import { useState, useRef } from 'react';
import {
  Stack,
  StackItem,
  TextField,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import Service from './service';
import { isMobile } from '../../utils';

export default function LoginPage({ setIsAuthenticated, setRole }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const id = useRef('');
  const password = useRef('');

  const login = async () => {
    setLoading(true);

    const idVal = id.current.value;
    const passwordVal = password.current.value;

    try {
      const result = await Service.login(idVal, passwordVal);
      localStorage.setItem('_cred', JSON.stringify(result));

      setRole(result.role);
      setIsAuthenticated(true);
    } catch (err) {
      console.error(err);
      setError(true);
    }

    setLoading(false);
  };

  return (
    <Stack style={{ height: '100vh' }} verticalAlign='center'>
      <StackItem align='center' style={isMobile ? { width: '80%' } : {}}>
        {error && <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel='Close'
        >
          Wrong ID or password.
        </MessageBar>}
        <div style={{ marginBottom: 15 }} />

        <TextField label='ID' placeholder='Enter ID' required componentRef={id} />
        <TextField label='Password' type='password' placeholder='Enter password' canRevealPassword required componentRef={password} />
        <div style={{ marginTop: 15 }} />
        <PrimaryButton
          text={
            <div style={{ display: 'flex' }}>
              {loading && <Spinner size={SpinnerSize.small} style={{ marginRight: 10 }} />}
              Login
            </div>
          }
          // style={{ width: '100%' }}
          disabled={loading}
          onClick={login}
          allowDisabledFocus
        />
      </StackItem>
    </Stack>
  );
}
