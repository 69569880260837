import {
  Panel,
  Stack,
  StackItem,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
} from '@fluentui/react';
import { useEffect, useState } from 'react';

export default function ItemListViewDrawer({
  visible,
  onClose,
  items,
  itemMap,
  title,
}) {
  const [columns, setColumns] = useState([
    { key: '1', name: 'Name', fieldName: 'name', minWidth: 150, maxWidth: 250, isResizable: true },
    { key: '2', name: 'Quantity', fieldName: 'quantity', minWidth: 100, maxWidth: 230, isResizable: true },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const processed = items.map((item) => ({ ...item, name: itemMap[item.itemId] }));
    setData(processed);
  }, [items, itemMap]);

  const onColumnClick = (e, column) => {

    let isSortedDescending = column.isSortedDescending;

    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Sort the items.
    let sortedItems = [];
    if (column.fieldName === 'quantity') {
      if (isSortedDescending) {
        sortedItems = data.sort((a, b) => b.quantity - a.quantity);
      } else {
        sortedItems = data.sort((a, b) => a.quantity - b.quantity);
      }
    } else {
      sortedItems = data.sort((a, b) => {
        if (isSortedDescending) {
          return (a.name < b.name) - (a.name > b.name);
        }
        return (a.name > b.name) - (a.name < b.name);
      });
    }

    const updatedColumns = columns.map(col => {
      col.isSorted = col.key === column.key;

      if (col.isSorted) {
        col.isSortedDescending = isSortedDescending;
      }

      return col;
    });

    setColumns(updatedColumns);
    setData(sortedItems);
  };

  return (
    <Panel
      isLightDismiss
      headerText={title}
      isOpen={visible}
      onDismiss={onClose}
      closeButtonAriaLabel='Close'
    >
      <Stack>
        <StackItem>
          <DetailsList
            items={data}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            onColumnHeaderClick={onColumnClick}
            selectionMode={SelectionMode.none}
            enterModalSelectionOnTouch={false}
          />
        </StackItem>
      </Stack>
    </Panel >
  );
}
