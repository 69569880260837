import ApiService from '../../services/ApiService';

const fetchItems = async (
  offset = 0,
  limit = 12,
  sorting = { field: 'name', order: 1 },
  filter = { field: '', value: '' }
) => {
  try {
    const { data } = await ApiService.get('/pd/items', {
      params: {
        offset,
        limit,
        sortBy: sorting.field,
        sortOrder: sorting.order,
        filterBy: filter.field,
        filterValue: filter.value,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    return {
      data: [],
      total: 0,
      strengthTotal: 0,
    };
  }
};

const fetchItemMap = async () => {
  const { data } = await ApiService.get('/pd/items/fetch_map');
  return data;
};

const fetchItemSummary = async () => {
  const { data } = await ApiService.get('/pd/items/fetch_summary');
  return data;
};

const createItem = async (item) => {
  const { data } = await ApiService.post('/pd/items', item);
  return data;
};

const editItem = async (id, update) => {
  const { data } = await ApiService.put(`/pd/items/${id}`, update);
  return data;
};

const deleteItem = async (id) => {
  try {
    const { data } = await ApiService.delete(`/pd/items/${id}`);
    const { success } = data;
    return success;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const Service = {
  fetchItems,
  fetchItemMap,
  fetchItemSummary,
  createItem,
  editItem,
  deleteItem,
};

export default Service;
