import { useEffect, useState } from 'react';
import {
  Panel,
  Nav,
  PanelType,
  ActionButton,
} from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { isMobile } from '../../utils';

const navStyles = {
  root: {
    width: 210,
    height: '100vh',
    boxSizing: 'border-box',
    border: '1px solid #eee',
    overflowY: 'auto',
  },
};

const navLinkGroups = [
  {
    name: 'Production Dashboard',
    links: [
      {
        name: 'Teams',
        to: '/',
        key: 'teams',
      },
      {
        name: 'Items',
        to: '/items',
        key: 'items',
      },
    ],
  },
];

function _onRenderGroupHeader(group) {
  return <h3 style={{ textAlign: 'center' }}>{group.name}</h3>;
}

export default function Layout({ setIsAuthenticated, children }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selected, setSelected] = useState('teams');
  const history = useHistory();

  useEffect(() => {
    const path = history.location.pathname.replace('/', '');
    if (path) {
      setSelected(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onLinkClick = (e, item) => {
    setSelected(item.key);
    setIsDrawerOpen(false);
    history.push(item.to);
  }

  const _buildNav = () => (
    <Nav
      onRenderGroupHeader={_onRenderGroupHeader}
      onLinkClick={_onLinkClick}
      selectedKey={selected}
      ariaLabel="Nav basic example"
      styles={navStyles}
      groups={navLinkGroups}
    />
  );

  return (
    <div style={{ display: 'flex', maxHeight: '100vh' }}>
      {isMobile ? (
        <Panel
          isLightDismiss
          isOpen={isDrawerOpen}
          hasCloseButton={false}
          onDismiss={() => setIsDrawerOpen(false)}
          styles={{
            content: { padding: 0 },
            commands: { marginTop: 0 },
          }}
          type={PanelType.customNear}
          customWidth={210}
        >
          {_buildNav()}
        </Panel>
      ) : _buildNav()}
      {/* {_buildNav()} */}
      <div style={{ flexGrow: 1, overflow: 'scroll' }}>
        <div style={{
          width: '100%',
          height: 55,
          border: '1px solid #eee',
          display: 'flex',
          alignItems: 'center',
          justifyContent: isMobile ? 'space-between' : 'flex-end',
        }}>
          {isMobile && (
            <ActionButton
              style={{ marginLeft: 15 }}
              iconProps={{ iconName: 'CollapseMenu' }}
              allowDisabledFocus
              onClick={() => setIsDrawerOpen(true)}
              text='Menu'
            />
          )}
          <ActionButton
            style={{ marginRight: isMobile ? 15 : 40 }}
            iconProps={{ iconName: 'ReleaseGate' }}
            allowDisabledFocus
            onClick={() => {
              localStorage.removeItem('_cred');
              setIsAuthenticated(false);
            }}
            text='Logout'
          />
        </div>
        {children}
      </div>
    </div>
  );
};
