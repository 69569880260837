import ApiService from '../../services/ApiService';

const fetchTeams = async (
  offset = 0,
  limit = 12,
  sorting = { field: 'name', order: 1 },
  filter = { field: '', value: '' }
) => {
  try {
    const { data } = await ApiService.get('/pd/teams', {
      params: {
        offset,
        limit,
        sortBy: sorting.field,
        sortOrder: sorting.order,
        filterBy: filter.field,
        filterValue: filter.value,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    return {
      data: [],
      total: 0,
      strengthTotal: 0,
    };
  }
};

const fetchItems = async () => {
  const { data } = await ApiService.get('/pd/items');
  return data;
};

const fetchItemSummary = async () => {
  const { data } = await ApiService.get('/pd/items/fetch_summary');
  return data;
};

const createTeam = async (team) => {
  const { data } = await ApiService.post('/pd/teams', team);
  return data;
};

const editTeam = async (id, update) => {
  const { data } = await ApiService.put(`/pd/teams/${id}`, update);
  return data;
};

const deleteTeam = async (id) => {
  try {
    const { data } = await ApiService.delete(`/pd/teams/${id}`);
    const { success } = data;
    return success;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const Service = {
  fetchTeams,
  fetchItems,
  fetchItemSummary,
  createTeam,
  editTeam,
  deleteTeam,
};

export default Service;
