import { useRef, useState } from 'react';
import {
  Panel,
  Stack,
  StackItem,
  TextField,
  PrimaryButton,
  SpinnerSize,
  Spinner,
} from '@fluentui/react';
import Service from './service';

export default function ContactDetailDrawer({
  visible,
  drawerMode = 'create',
  onSuccess,
  onError,
  onClose,
  selectedItem,
}) {
  const [loading, setLoading] = useState(false);

  const nameRef = useRef('');

  const create = async () => {
    setLoading(true);
    try {
      const result = await Service.createItem({
        name: nameRef.current.value,
      });
      onSuccess('Item added successfully.', result);
    } catch (err) {
      console.error(err);
      onError('There is an error in adding the item.');
    }

    setLoading(false);
  };

  const edit = async () => {
    setLoading(true);
    try {
      const result = await Service.editItem(selectedItem._id, {
        name: nameRef.current.value,
      });
      onSuccess('Item updated successfully.', result);
    } catch (err) {
      console.error(err);
      onError('There is an error in updating the item.');
    }

    setLoading(false);
  };

  return (
    <Panel
      headerText={drawerMode === 'create' ? 'Create Item' : 'Edit Item'}
      isOpen={visible}
      onDismiss={onClose}
      closeButtonAriaLabel='Close'
    >
      <Stack tokens={{ childrenGap: 12 }}>
        <StackItem>
          <TextField label='Name' placeholder='Enter Name' componentRef={nameRef} defaultValue={selectedItem.name} />
        </StackItem>
        <StackItem style={{ marginTop: 20 }}>
          <PrimaryButton
            text={
              <div style={{ display: 'flex' }}>
                {loading && <Spinner size={SpinnerSize.small} style={{ marginRight: 10 }} />}
                {drawerMode === 'create' ? 'Create Item' : 'Edit Item'}
              </div>
            }
            disabled={loading}
            onClick={drawerMode === 'create' ? create : edit}
            allowDisabledFocus
          />
        </StackItem>
      </Stack>
    </Panel>
  );
}
