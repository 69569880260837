import { useEffect, useRef, useState } from 'react';
import {
  Panel,
  Stack,
  StackItem,
  TextField,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  Dropdown,
  PanelType,
  Label,
  IconButton,
} from '@fluentui/react';
import Service from './service';

export default function ContactDetailDrawer({
  visible,
  drawerMode = 'create',
  onSuccess,
  onError,
  onClose,
  selectedItem,
  items: itemData,
}) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const nameRef = useRef('');
  const contactRef = useRef('');

  useEffect(() => {
    if (selectedItem.items) {
      setItems(selectedItem.items);
    }
  }, [selectedItem]);

  const create = async () => {
    setLoading(true);
    try {
      const result = await Service.createTeam({
        name: nameRef.current.value,
        contact: contactRef.current.value,
        items,
      });
      onSuccess('Team added successfully.', result);
    } catch (err) {
      console.error(err);
      onError('There is an error in adding the team.');
    }

    setLoading(false);
  };

  const edit = async () => {
    setLoading(true);
    try {
      const result = await Service.editTeam(selectedItem._id, {
        name: nameRef.current.value,
        contact: contactRef.current.value,
        items,
      });
      onSuccess('Team updated successfully.', result);
    } catch (err) {
      console.error(err);
      onError('There is an error in updating the team.');
    }

    setLoading(false);
  };

  const handleFilterChange = (option, i) => {
    const data = [...items];
    data[i].itemId = option.key;
    setItems(data);
  };

  const handleQtyChange = (newValue, i) => {
    const data = [...items];
    data[i].quantity = newValue;
    setItems(data);
  };

  return (
    <Panel
      headerText={drawerMode === 'create' ? 'Create Team' : 'Edit Team'}
      isOpen={visible}
      onDismiss={onClose}
      type={PanelType.custom}
      customWidth={400}
      closeButtonAriaLabel='Close'
    >
      <Stack tokens={{ childrenGap: 12 }} style={{ marginTop: 15 }}>
        <StackItem>
          <TextField
            label='Name'
            placeholder='Enter Name'
            componentRef={nameRef}
            defaultValue={selectedItem.name}
          />
        </StackItem>
        <StackItem>
          <TextField
            label='Contact Number'
            placeholder='Enter Contact number'
            componentRef={contactRef}
            defaultValue={selectedItem.contact}
          />
        </StackItem>
        <StackItem>
          <Label>Items</Label>
          {items.map((item, i) => (
            <div key={i} style={{ display: 'flex', marginBottom: 10 }}>
              <Dropdown
                selectedKey={item.itemId}
                placeholder='Select an item'
                options={itemData.map((item) => ({ key: item._id, text: item.name }))}
                styles={{
                  dropdown: { width: 230, marginRight: 10 },
                }}
                onChange={(e, op) => handleFilterChange(op, i)}
              />
              <TextField
                type='number'
                placeholder='Quantity'
                defaultValue={item.quantity}
                onChange={(e, nv) => handleQtyChange(nv, i)}
              />
              <IconButton
                iconProps={{ iconName: 'Delete' }}
                style={{ color: 'red' }}
                title='Delete'
                ariaLabel='Delete'
                onClick={() => {
                  const filtered = [...items.slice(0, i), ...items.slice(i + 1, items.length)];
                  setItems(filtered);
                }}
              />
            </div>
          ))}
          <DefaultButton
            text='+ Add Item'
            onClick={() => setItems([...items, { itemId: '', quantity: 0 }])}
            allowDisabledFocus
          />
        </StackItem>
        <StackItem style={{ marginTop: 20 }}>
          <PrimaryButton
            text={
              <div style={{ display: 'flex' }}>
                {loading && <Spinner size={SpinnerSize.small} style={{ marginRight: 10 }} />}
                {drawerMode === 'create' ? 'Create Team' : 'Edit Team'}
              </div>
            }
            disabled={loading}
            onClick={drawerMode === 'create' ? create : edit}
            allowDisabledFocus
          />
        </StackItem>
      </Stack>
    </Panel >
  );
}
