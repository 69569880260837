import { useEffect, useState } from 'react';
import {
  Stack,
  StackItem,
  Text,
  Link,
  Dropdown,
  ShimmeredDetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  IconButton,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
  MessageBar,
  MessageBarType,
  CommandBarButton,
} from '@fluentui/react';
import {
  Pagination,
} from '@uifabric/experiments';
import Service from './service';
import ContactDetailDrawer from './TeamDetailDrawer';
import ItemListViewDrawer from './ItemListView';
import { isMobile } from '../../utils';

const ROW_PER_PAGE = 12;

export default function TeamListPage({ role, setIsAuthenticated }) {
  const [columns, setColumns] = useState([
    { key: '1', name: 'Name', fieldName: 'name', minWidth: 150, maxWidth: 250, isResizable: true, isSorted: true, },
    { key: '2', name: 'Phone Number', fieldName: 'contact', minWidth: 100, maxWidth: 230, isResizable: true },
    { key: '3', name: 'Items', fieldName: 'items', minWidth: 100, maxWidth: 230, isResizable: true },
  ]);

  const [items, setItems] = useState([]);
  const [itemMap, setItemMap] = useState({});
  const [, setItemSummary] = useState([]);

  const [data, setData] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [sortOrder, setSortOrder] = useState({
    field: 'name',
    order: 1,
  });
  const [selectedItem, setSelectedItem] = useState({ items: [] });

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(5);

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const [message, setMessage] = useState({
    visible: false,
    type: MessageBarType.success,
    content: '',
  });

  const [dialogVisible, setDialogVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerMode, setDrawerMode] = useState('create');
  const [dataDrawerVisible, setDataDrawerVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data: result, total } = await Service.fetchTeams(0, ROW_PER_PAGE);
      const { data: itemData } = await Service.fetchItems();
      const summary = await Service.fetchItemSummary();
      setItemSummary(summary);

      const map = itemData.reduce((a, b) => ({ ...a, [b._id]: b.name }), {});

      setItems(itemData);
      setItemMap(map);
      setPageCount(Math.floor(total / ROW_PER_PAGE) + 1);
      setPage(0);
      setData(result);

      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (role === 'administrator') {
      setColumns([
        {
          key: '1', name: 'Name', fieldName: 'name',
          minWidth: 140,
          maxWidth: 200,
          isResizable: true, isSorted: true,
        },
        {
          key: '2', name: 'Phone Number', fieldName: 'contact',
          minWidth: 140,
          maxWidth: 200,
          isResizable: true
        },
        {
          key: '3', name: 'Items', fieldName: 'items',
          minWidth: 140,
          maxWidth: 200,
          isResizable: true
        },
        {
          key: '4', name: 'Actions', fieldName: 'actions',
          minWidth: 140,
          maxWidth: 200,
          isResizable: true
        },
      ]);
    }
  }, [role]);

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName];

    switch (column.fieldName) {
      case 'items':
        return (
          // <div style={{ display: 'flex', flexDirection: 'column' }}>
          //   {fieldContent.map((item) => (
          //     <div key={item._id} style={{ display: 'flex', justifyContent: 'space-between' }}>
          //       <span>{itemMap[item.itemId]}</span>
          //       <span style={{ marginRight: 20 }}>{item.quantity}</span>
          //     </div>
          //   ))}
          // </div>
          <CommandBarButton iconProps={{ iconName: 'MiniExpand' }} text='View Items' onClick={() => {
            setSelectedItem(item);
            setDataDrawerVisible(true);
          }} />
          // <Link href=''>View Items</Link>
        );

      case 'contact':
        return <Link href={`tel:${fieldContent}`}>{fieldContent}</Link>;

      case 'actions':
        return (
          <div>
            <IconButton
              iconProps={{ iconName: 'Edit' }}
              title='Edit'
              ariaLabel='Edit'
              onClick={() => {
                setSelectedItem(item);
                setDrawerMode('edit');
                setDrawerVisible(true);
              }}
            />
            <IconButton
              iconProps={{ iconName: 'Delete' }}
              styles={{
                icon: { color: 'red' },
              }}
              title='Delete'
              ariaLabel='Delete'
              onClick={() => {
                setSelectedItem(item);
                setDialogVisible(true);
              }}
            />
          </div>
        );

      default:
        return <span>{fieldContent}</span>;
    }
  }

  const onColumnClick = async (event, column) => {
    if (column.fieldName === 'phone' || column.fieldName === 'items' || column.fieldName === 'actions') return;
    setLoading(true);

    let isSortedDescending = column.isSortedDescending;

    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Sort the items.
    const { data: sortedItems } = await Service.fetchTeams(
      0,
      ROW_PER_PAGE,
      { field: column.fieldName, order: isSortedDescending ? -1 : 1 },
      { field: 'item', value: filterValue },
    );

    const updatedColumns = columns.map(col => {
      col.isSorted = col.key === column.key;

      if (col.isSorted) {
        col.isSortedDescending = isSortedDescending;
      }

      return col;
    })

    // // Reset the items and columns to match the state.
    setSortOrder({
      field: column.fieldName, order: isSortedDescending ? -1 : 1,
    });
    setData(sortedItems);
    setColumns(updatedColumns);
    setPage(0);
    setLoading(false);
  };

  const handleFilterChange = async (e, option) => {
    setLoading(true);

    let item = option.key;
    if (item === 'default') {
      item = '';
    }

    const { data: filtered, total } = await Service.fetchTeams(
      0,
      ROW_PER_PAGE,
      { field: sortOrder.field, order: sortOrder.order },
      { field: 'item', value: item },
    );

    setPage(0);
    setPageCount(Math.floor(total / ROW_PER_PAGE) + 1);
    setFilterValue(item);
    setData(filtered);
    setLoading(false);
  };

  const onPageChange = async (val) => {
    setLoading(true);

    const { data: result } = await Service.fetchTeams(val, ROW_PER_PAGE, sortOrder, { field: 'item', value: filterValue });
    setPage(val);
    setData(result);

    setLoading(false);
  };

  const deleteContact = async () => {
    setBtnLoading(true);

    const result = await Service.deleteTeam(selectedItem._id);
    if (result) {
      const filtered = data.filter((doc) => doc._id !== selectedItem._id);
      setData(filtered);
      setMessage({
        visible: true,
        type: MessageBarType.success,
        content: 'Team deleted successfully.',
      });
    } else {
      setMessage({
        visible: true,
        type: MessageBarType.error,
        content: 'There is an error in deleting the team.',
      });
    }

    setBtnLoading(false);
    setDialogVisible(false);
  };

  const buildPageHeader = () => (
      <>
        <StackItem style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <Text variant='xLarge'><b>Teams List</b></Text>
            {role === 'administrator' ? (
              <PrimaryButton onClick={() => {
                setSelectedItem({
                  name: '', contact: '', items: [],
                });
                setDrawerMode('create');
                setDrawerVisible(true);
              }} text='+ Add Team' />
            ) : null}
        </StackItem>
        <StackItem>
          <Dropdown
            // defaultSelectedKey={DEFAULT_KEY}
            placeholder='Select an item'
            label='Filter by item'
            options={[ { key: 'default', text: 'All Items' }, ...items.map((item) => ({ key: item._id, text: item.name })) ]}
            styles={{
              dropdown: { width: 250 },
            }}
            onChange={handleFilterChange}
          />
        </StackItem>
      </>
    );

  const responsiveMargin = isMobile ? 15 : 40;

  return (
    <>
      <Stack
        tokens={{
          childrenGap: 10,
          padding: 10,
        }}
        style={{ marginLeft: responsiveMargin, marginRight: responsiveMargin }}
      >
        {buildPageHeader()}
        {message.visible && (
          <StackItem style={{ margin: isMobile ? '10px 0px 0px 0px' : '10px 0px' }}>
            <MessageBar
              messageBarType={message.type}
              isMultiline={false}
              onDismiss={() => setMessage({ ...message, visible: false })}
              dismissButtonAriaLabel='Close'
            >
              {message.content}
            </MessageBar>
          </StackItem>
        )}
        <StackItem>
          <ShimmeredDetailsList
            items={data}
            enableShimmer={loading}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            onRenderItemColumn={renderItemColumn}
            onColumnHeaderClick={onColumnClick}
            selectionMode={SelectionMode.none}
            enterModalSelectionOnTouch={false}
          />
        </StackItem>
        <StackItem align='end' style={{ margin: '10px 0px' }}>
          <Pagination
            selectedPageIndex={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        </StackItem>
      </Stack>

      <Dialog
        hidden={!dialogVisible}
        onDismiss={() => setDialogVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Delete Contact',
          closeButtonAriaLabel: 'Close',
          subText: `Do you want to delete '${selectedItem.name}'?`,
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteContact} text='Delete' disabled={btnLoading} style={{ backgroundColor: 'red', borderColor: 'red' }} />
          <DefaultButton onClick={() => setDialogVisible(!dialogVisible)} text='Cancel' />
        </DialogFooter>
      </Dialog>

      <ContactDetailDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        selectedItem={selectedItem}
        items={items}
        drawerMode={drawerMode}
        onSuccess={(msg, updated) => {
          setMessage({
            type: MessageBarType.success,
            content: msg,
            visible: true,
          });
          setDrawerVisible(false);

          if (drawerMode === 'create') {
            const updatedList = [...data, updated];
            setData(updatedList);
          } else {
            const updatedList = data.map((doc) => {
              if (doc._id === updated._id) {
                return updated;
              }
              return doc;
            });

            setData(updatedList);
          }
        }}
        onError={(msg) => {
          setMessage({
            type: MessageBarType.error,
            content: msg,
            visible: true,
          });
          setDrawerVisible(false);
        }}
      />

      <ItemListViewDrawer
        visible={dataDrawerVisible}
        items={selectedItem.items}
        itemMap={itemMap}
        onClose={() => setDataDrawerVisible(false)}
        title={selectedItem.name ? `${selectedItem.name} - Items` : 'Items Summary'}
      />
    </>
  );
}
