import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import TeamListPage from './pages/Teams';
import Layout from './pages/Layout';
import LoginPage from './pages/Login';
import ItemListPage from './pages/Items';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState('user');

  useEffect(() => {
    let creds = localStorage.getItem('_cred');
    if (creds) {
      creds = JSON.parse(creds);

      if (Date.now() < creds.expiresIn) {
        setRole(creds.role);
        setIsAuthenticated(true);
      }
    }
  }, []);

  return isAuthenticated ? (
    <BrowserRouter>
      <Layout setIsAuthenticated={setIsAuthenticated}>
        <Switch>
          <Route path='/' exact>
            <TeamListPage role={role} setIsAuthenticated={setIsAuthenticated} />
          </Route>
          <Route path='/items' exact>
            <ItemListPage role={role} setIsAuthenticated={setIsAuthenticated} />
          </Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  ) : <LoginPage setIsAuthenticated={setIsAuthenticated} setRole={setRole} />;
}

export default App;
